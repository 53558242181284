<template>
  <div class="container">
    <div class="header"><span class="line"></span> 佣金报表</div>
    <div class="nav">
    </div>
    <div class="search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :lg="5" :md="8" :sm="12">
            <a-form-item label="人员名称" :labelCol="{lg: {span: 10}, sm: {span: 7}}" :wrapperCol="{lg: {span: 14}, sm: {span: 17} }">
              <a-input v-model="queryParam.serviceName" placeholder="请输入服务人员名称"/>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="8" :sm="12">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="handleToSearchEnterprise">查询</a-button>
              <a-button style="margin-left: 8px" icon="reload" @click="resetSearchEnterprise">重置</a-button>
              <a-button style="margin-left: 8px" type="primary" :disabled="!hasSelected" :loading="loading" @click="surePay">发起付款</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <s-table
        style="border-top:1px solid #F0F0F0;margin-top:16px"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        ref="table"
        :columns="raskColumns"
        :data="raskData"
        :rowKey="(record) => record.serviceId"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="detail(record)">查看详情</a>
        </span>
        <span slot="avator" v-if="record.headUrl" slot-scope="text, record">
          <img :src="record.headUrl" style="width:30px;height:30px" alt="">
        </span>
        <span slot="payType" slot-scope="text, record">
          <img v-if="record.payType==='30'" src="../../assets/zhifu_pay.png" style="width:30px;height:30px" alt="">
          <img v-if="record.payType==='20'" src="../../assets/weixin_pay.png" style="width:30px;height:30px" alt="">
        </span>
      </s-table>
    </div>
    <!-- 添加 -->
    <a-modal
      title="发起付款"
      :visible="visibleAdd"
      :confirm-loading="confirmLoadingAdd"
      @ok="handleOkAdd"
      @cancel="handleCancelAdd"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item :label="item.serviceName" v-for="(item,index) in selectedRows" :key="index">
          <a-input placeholder="请输入付款金额" v-model="item.settlementAmount"/>
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 详情 -->
    <a-modal title="详情" width="100%" v-if="visibleDetailModal" class="detail-modal" :footer="null" :visible="visibleDetailModal" @cancel="visibleDetailModal = false;">
      <Detail v-if="visibleDetailModal" :id="currentId"></Detail>
    </a-modal>
  </div>
</template>
<script>
import STable from '@/components/table/';
import Detail from './components/detail';
import { ACCESS_TOKEN,USER_INFO } from "@/store/mutation-types";
import Vue from 'vue'
import {
  getRebateData,
  createSettlement
} from '@/api/api';
export default {
  components: {
    STable,
    Detail
  },
  data () {
    return {
      raskColumns: [
        {
          title: '服务人员名称',
          align: 'center',
          dataIndex: 'serviceName',
        },
        {
          title: '任务名称',
          align: 'center',
          dataIndex: 'taskTitle',
        },
        {
          title: '服务门店数量',
          align: 'center',
          dataIndex: 'serviceMchNum',
        },
        {
          title: '交易门店数量',
          dataIndex: 'tradeMchNum',
          align: 'center',
        },
        {
          title: '订单总数量',
          dataIndex: 'orderNum',
          align: 'center',
        },
        {
          title: '累计交易金额',
          dataIndex: 'tradeAmt',
          align: 'center',
          customRender: text => text===null? '--': Number(text).toFixed(2)
        },
        {
          title: '累计提成金额',
          align: 'center',
          dataIndex: 'rebateAmt',
          customRender: text => text===null? '--': Number(text).toFixed(2)
        },
        {
          title: '结算金额',
          align: 'center',
          dataIndex: 'settlementAmount',
          customRender: text => text===null? '--': Number(text).toFixed(2)
        },
        {
          title: '最近交易时间',
          dataIndex: 'lastTradeTime',
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        }
      ],
      currentId: '',
      visibleDetailModal: false,
      visibleAdd: false,
      confirmLoadingAdd:false,
      formLayout: 'horizontal',
      form: this.$form.createForm(this, { name: 'coordinated' }),
      queryParam: {
        serviceName: '',
        status: undefined,
        startTime: undefined,
        endTime: undefined,
        id: undefined
      },
      date: [],
      selectedRowKeys: [],
      selectedRows: [],
      token: ''
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  created () {
    this.token = Vue.ls.get(ACCESS_TOKEN)
  },
  methods: {
    // 选择表格
    onSelectChange(selectedRowKeys,values) {
      values.forEach(item=>{
        item.settlementAmount = ''
      })
      console.log('selectedRowKeys changed: ', selectedRowKeys,values);
      this.selectedRows = values
      this.selectedRowKeys = selectedRowKeys;
    },
    // 选择时间
    onChange(date, dateString) {
      this.queryParam.startTime = dateString[0]
      this.queryParam.endTime = dateString[1]
    },
    // 查看详情
    detail (record) {
      this.currentId = record
      this.visibleDetailModal = true
    },
    // 获取列表
    raskData (parameter) {
      return getRebateData(Object.assign(this.queryParam,parameter)).then(res => {
        return res.result
      })
    },
    // 查询    
    handleToSearchEnterprise() {
      this.$refs.table.refresh(true)
    },
    // 重置
    resetSearchEnterprise() {
      this.queryParam = {
        serviceName: '',
        status: undefined,
        startTime: undefined,
        endTime: undefined,
        id: undefined,
      };
      this.date = [];
      this.handleToSearchEnterprise();
    },
    // 发起付款
    handleOkAdd (e) {
      let allowAdd = true
      this.selectedRows.forEach(item=>{
        if (item.settlementAmount==='') {
          allowAdd = false
          this.$message.error(`${item.serviceName}付款金额不能为空`)
        }
      })
      if (!allowAdd) {
        return
      }
      this.confirmLoadingAdd = true
      createSettlement({models: this.selectedRows}).then(res=> {
        this.confirmLoadingAdd = false
        if (res.success) {
          this.handleCancelAdd()
          this.$message.success(res.message)
          let that = this
          this.$confirm({
            title: '佣金付款单已提交成功，请前往【分时领英-付款管理】模块，进行审核、付款操作！确定前往？',
            onOk() {
              return new Promise((resolve, reject) => {
                let url = window._CONFIG['jumpUrl'] + that.token
                window.open(url)
                resolve()
              }).catch(() => console.log('Oops errors!'));
            },
            onCancel() {
              that.handleToSearchEnterprise()
            },
          });
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleCancelAdd () {
      this.confirmLoadingAdd = false
      this.visibleAdd = false
    },
    // 发起付款
    surePay () {
      this.visibleAdd = true
    }
  }
}
</script>
<style scoped lang="less">
.container{
  background: #FFFFFF;
  border-radius: 4px;
  height: 935px;
  padding: 40px;
}
.header{
  .line{
    width: 2px;
    height: 18px;
    background: #347CF5;
    margin-right: 9px;
  }
  color: #333333;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.nav{
  margin: 10px 0 0 0;
  .common{
    color: #999999;
    margin: 0;
  }
}
.opt{
  margin: 20px 0 30px 0;
}
.search-wrapper{
  margin: 30px 0;
}
</style>
<style lang="less">
.detail-modal {
  top: 0;
  height: 100vh;
  .ant-modal-content {
    height: 100vh;
    margin-top: -100px;
    .ant-modal-body {
      height: calc(100%-55px);
      padding: 0;
    }
  }
}
</style>